.showcase {
  height: 100%;
  min-height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 48px 1fr;
}

.showcase__menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.showcase__menu > img {
  width: 28px;
  margin: 10px;
}

.showcase__description {
  background: #ffffff;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.showcase__title {
  font-size: 2em;
  font-family: beloved-sans, sans-serif;
  font-weight: 700;
  color: #01043f;
  margin: 0;
  line-height: 1em;
  text-transform: lowercase;
}

.showcase__copy {
  margin: 0;
  margin-top: 2em;
  line-height: 1.6em;
  color: #59595b;
  text-align: justify;
  font-family: 'Anonymous Pro', monospace;
  font-size: 0.9em;
}

.showcase__category {
  font-family: beloved-sans, sans-serif;
  font-weight: 700;
  padding: 0 0.5em;
  text-decoration: none;
  color: #59595b;
}

.showcase__category:hover {
  color: #ffffff;
}

.showcase__gallery {
  padding: 24px;
  background: #f3efc2;
}

.showcase__gallery > img {
  width: 100%;
  max-width: 780px;
  display: block;
  margin: 0;
}

.showcase__gallery > img:not(:last-child) {
  margin-bottom: 24px;
}

@media (min-width: 768px) {
  .showcase {
    grid-template-columns: 48px 1fr 2fr;
    grid-template-rows: 1fr;
  }

  .showcase__description {
    padding: 48px;
  }

  .showcase__gallery {
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 48px;
  }

  .showcase__gallery > img:not(:last-child) {
    margin-bottom: 48px;
  }
}
