.splash {
  height: auto;
  min-height: 320px;
  padding: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #faeae4;
  animation: fadeIn 2s;
  position: relative;
  overflow: hidden;
  z-index: 2;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to   { opacity: 0; }
}

.fade-out {
  animation: fadeOut 2s;
}

.fade-in {
  animation: fadeIn 2s;
}

.splash:after,
.splash:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
}

.splash:before {
  background-image: url('../../assets/bg_spots.png');
  animation: animateBackground 60s infinite;
  z-index: 3;
}

.splash:after {
  background-image: url('../../assets/bg_swoosh.png');
  animation: animateBackgroundReverse 60s infinite;
  z-index: 1;
}

@keyframes animateBackground {
  0% { transform: scale(1.0) }
  50% { transform: scale(1.2) }
  100% { transform: scale(1.0) }
}

@keyframes animateBackgroundReverse {
  0% { transform: scale(1.2) }
  50% { transform: scale(1.0) }
  100% { transform: scale(1.2) }
}

.splash__header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.splash__title {
  font-size: 4em;
  margin: 0;
  color: #ffffff;
  font-family: 'Raleway', sans-serif;
  text-align: center;
}

.splash__subtitle {
  margin: 0;
  color: #deb7a8;
  margin-top: -0.2em;
  font-family: beloved-sans, sans-serif;
  font-weight: 400;
  letter-spacing: 2px;
  text-align: center;
  text-transform: uppercase;
}

.splash__scroll,
.splash__scroll:before {
  position: absolute;
  left: 50%;
}

.splash__scroll {
  width: 32px;
  height: 56px;
  margin-left: -16px;
  bottom: 48px;
  margin-top: -35px;
  border-radius: 16px;
  background: #ffffff;
}

.splash__scroll:before {
  content: '';
  width: 8px;
  height: 8px;
  background: #090040;
  margin-left: -4px;
  top: 8px;
  border-radius: 8px;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-name: scroll;
}

.splash__nav-item {
  position: absolute;
  top: 24px;
  text-transform: uppercase;
  font-size: 0.8em;
  font-weight: 200;
  background: #ffffff;
  padding: 8px;
  letter-spacing: 0.1em;
  text-decoration: none;
  color: #090040;
  z-index: 4;
}

.splash__nav-item:hover {
  color: #b9d5ed;
}

@keyframes scroll {
  0% {
    opacity: 1;
  }
  100% {
    transform: translateY(16px);
  }
}

@media (min-width: 768px) {
  .splash {
    min-height: 100%;
    padding: 48px;
  }

  .splash__title {
    font-size: 11em;
  }

  .splash__subtitle {
    font-size: 2.5em;
    font-weight: 400;
  }

  .splash__nav-item {
    top: 48px;
    font-size: 1.2em;
  }
}
