.projects {
  height: auto;
  min-height: auto;
  padding: 24px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.projects__grid {
  min-height: 100%;
  margin: 0 auto;
  display: block;
}

@media (min-width: 768px) {
  .projects {
    min-height: 100%;
    padding: 48px;
  }

  .projects__grid {
    min-width: 800px;
    max-width: 1224px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, 376px);
    grid-template-rows: 216px;
    grid-gap: 48px;
    grid-auto-rows: 216px;
    justify-content: center;
  }
}
