.about {
  height: 100%;
  min-height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 48px 1fr;
}

.about__menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about__menu > img {
  width: 28px;
  margin: 10px;
}

.about__description {
  background: #ffffff;
  padding: 24px;
  display: flex;
  flex-direction: column;
}

.about__title {
  font-size: 2em;
  font-family: beloved-sans, sans-serif;
  font-weight: 700;
  color: #01043f;
  margin: 0;
  line-height: 1em;
  text-transform: lowercase;
}

.about__copy {
  margin: 0;
  margin-top: 2em;
  line-height: 1.6em;
  color: #59595b;
  font-size: 0.9em;
  text-align: justify;
  font-family: 'Anonymous Pro', monospace;
}

.about__links {
  padding-top: 32px;
  text-align: center;
}

.about__links img {
  width: 48px;
}

.about__links button {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
}

.about__links a:first-child,
.about__links button {
  margin-right: 32px;
}

.about__image {
  height: 320px;
}

@media (min-width: 768px) {
  .about {
    grid-template-columns: 48px 1fr 1fr;
    grid-template-rows: 1fr;
  }

  .about__description {
    overflow-y: scroll;
    padding: 48px;
  }

  .about__image {
    height: 100%;
  }
}
