.projects-root {
  height: 100%;
  min-height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 48px 1fr;
}

.projects-root__menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.projects-root__menu > img {
  width: 28px;
  margin: 10px;
}

.projects-root .projects {
  padding: 24px;
}

@media (min-width: 768px) {
  .projects-root {
    grid-template-columns: 48px 1fr;
    grid-template-rows: 1fr;
  }

  .projects-root .projects {
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 48px;
  }
}

