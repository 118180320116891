.project {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-self: center;
  align-items: center;
}

.project__title {
  margin: 0;
  color: #ffffff;
  font-family: 'Raleway', sans-serif;
  text-align: center;
  text-transform: lowercase;
}

.project__subtitle {
  margin: 0;
  margin-top: 0.2em;
  text-align: center;
  font-size: 0.8em;
  font-family: beloved-sans, sans-serif;
  font-weight: 700;
  color: #59595b;
  text-transform: uppercase;
}

.project__image {
  width: 100%;
  /* width: 376px; */
  /* height: 216px; */
}

.project:not(:last-child) {
  margin-bottom: 24px
}

@media (min-width: 768px) {
  .project--small > .project__title {
    font-size: 2em;
    line-height: 1em;
  }

  .project--large > .project__title,
  .project--portrait > .project__title {
    font-size: 2.6em;
    line-height: 1em;
  }

  .project__subtitle {
    margin-top: 0.8em;
    font-size: 0.8em;
  }

  .project--small {
    max-height: 216px;
    max-width: 376px;
    /* min-width: 376px; */
    width: 100%;
  }

  .project--large {
    height: 480px;
    /* min-width: 800px; */
    width: 100%;
    grid-row: span 2;
    grid-column: span 2;
  }

  .project--portrait {
    height: 480px;
    /* min-width: 376px; */
    width: 100%;
    grid-row: span 2;
  }

  .project:not(:last-child) {
    margin-bottom: 0;
  }
}

