.bg--pink {
  background: #faeae4;
}

.bg--green {
  background: #a9d8b9;
}

.bg--blue {
  background: #b9d5ed;
}

.bg--yellow {
  background: #f3efc2;
}

.bg--dark-pink {
  background: #deb7a8;
}

.bg--white {
  background: #ffffff;
}
